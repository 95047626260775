:root {
  --primary: #0D406E;
  --secondary: #18B9B1;
  --action: #F06362;
  --text: #373A3C;
}

body {
  background-color: #f9f9f9;
  padding: 0;
  margin: 0;
  color: var(--text);
}

.login-box {
  margin-top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.login-header {
  background-color: var(--secondary);
  padding: 2rem;
  margin-top: -1rem;
  margin-bottom: 2rem;
  border-radius: 4px;
  width: 90%;
  text-align: center;
}

.login-body {
  width: 60%;
  margin-bottom: 2rem;
}

.login-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-input {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  width: 100%
}

.login-input svg {
  margin-right: 5px;
}

.login-copy {
  margin-bottom: 1rem;
}

.login-button {
  background-color: var(--primary) !important;
  color: white !important;
}

.login-disclaimer {
  margin: 1rem;
  font-size: 0.75rem;
  color: #666;
}

.login-errors {
  color: red;
  font-weight: bold;
  margin: 1rem auto;
}

.code-entry-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-rules {
  padding: 1rem;
}

.password-valid {
  border: 1px solid var(--secondary);
  border-radius: 4px;
}

.password-rules .MuiListItemIcon-root {
  min-width: 35px;
}

.password-valid .MuiListItemIcon-root {
  color: var(--primary);
}

.rememberCheckbox {
  margin-left: .25rem;
  padding-bottom: 1.5rem;
}

.remember-checkbox .MuiFormControlLabel-label {
  font-size: .75rem !important;
}

.loading-icon {
  margin-left: .75rem;
}

.full-width {
  width: 100%;
}